import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'

import 'animate.css';



//适配移动端
import 'lib-flexible/flexible.js';

// 多分辨率屏幕适配
import './rem.js'

import 'element-ui/lib/theme-chalk/base.css';
// collapse 展开折叠
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition';

Vue.component(CollapseTransition.name, CollapseTransition)

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import VideoPlayer from 'vue-video-player'
import 'video.js/dist/video-js.css'
import 'vue-video-player/src/custom-theme.css'
Vue.use(VideoPlayer)

import VueLazyload from 'vue-lazyload'
Vue.use(VueLazyload, {
  //完全显示的时候加载
  preLoad: 1,
  //失败时显示的图片
  // error: require('./assets/img/error.png'),
  //加载时显示的GIF图
  loading: require('./assets/img/loading.gif'),
  //尝试加载几次
  attempt: 1
});

Vue.config.productionTip = false

//设置超时时间
axios.defaults.timeout = 5000
//设置api地址
//全局定义axios
axios.defaults.baseURL = 'http://127.0.0.1:8000/api'
Vue.prototype.$axios = axios

// 图片服务器地址
Vue.prototype.imgserver = 'http://127.0.0.1:8000/api'

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
