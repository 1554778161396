<template>
  <router-view/>
</template>

<script>

export default {
  name: 'App',
  created () {
    this.$nextTick(() => {
      this.resizeFun()
      // window.addEventListener('resize', this.resizeFun)
    })
  },
  methods: {
    resizeFun () {
      const devicePixelRatio = window.devicePixelRatio // 获取下载的缩放 125% -> 1.25    150% -> 1.5
      if (devicePixelRatio >= 1.6) {
        // 如果在笔记本中用IE浏览器打开 则弹出提示
        if (!! window.ActiveXObject || 'ActiveXObject' in window) {
          alert('2023年了还用ie浏览器？')
        } else {
          const c = document.querySelector('body')
          c.style.zoom = 1.6 / devicePixelRatio// 修改页面的缩放比例
        }
      }
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>


