import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)


let router = new Router({
    routes: [
        {
            path: '/',
            name: 'home',
            component: () => import('@/page/HomeView.vue'),
            children: [
                {
                    path:'/',
                    name: 'info',
                    component: () => import('@/components/HomeInfo.vue'),
                },

                {
                    path:'/about',
                    name: 'about',
                    component: () => import('@/page/OrbisInfo.vue'),
                },
                {
                    path:'/development',
                    name: 'development',
                    component: () => import('@/page/DevelopmentView.vue'),
                },
                {
                    path:'/product',
                    name:'product',
                    component: () => import('@/page/ProductView.vue'),
                },
                {
                    path:'/honor',
                    name:'honor',
                    component: () => import('@/page/HonorView.vue'),
                },
                {
                    path:'/intellectual',
                    name:'intellectual',
                    component: () => import('@/page/IntellectualView.vue'),
                },
                {
                    path:'/partners',
                    name:'partners',
                    component: () => import('@/page/PartnerView.vue'),
                },
                {
                    path:'/research',
                    name:'research',
                    component: () => import('@/page/ResearchView.vue'),
                },
                {
                    path:'/academic',
                    name:'academic',
                    component: () => import('@/page/AcademicView.vue'),
                }

            ]
        },

        {
            path:'/test',
            name: 'test',
            component: () => import('@/page/TestView.vue'),
        },


        {
            path: '/ear',
            name: 'ear',
            component: () => import('@/components/EarTry.vue')
        },
        {
            path: '/1',
            name: '1',
            component: () => import('@/paper/OneView.vue')
        },
        {
            path: '/2',
            name: '2',
            component: () => import('@/paper/TwoView.vue')
        },
        {
            path: '/3',
            name: '3',
            component: () => import('@/paper/ThreeView.vue')
        },
        {
            path: '/4',
            name: '4',
            component: () => import('@/paper/FourView.vue')
        },
        {
            path: '/software/1',
            component: () => import('@/software/EyeScreeningView.vue')
        },
        {
            path: '/software/2',
            component: () => import('@/software/CampusView.vue')
        },
        {
            path: '/software/3',
            component: () => import('@/software/FundusView.vue')
        },
        {
            path: '/software/custom',
            component: () => import('@/software/CustomizeView.vue')
        }


        // {
        //     path: '/paper',
        //     name: 'paper',
        //
        // }

    ]
})

router.beforeEach((to, from, next) => {
    // 兼容chrome
    document.body.scrollTop = 0
    // 兼容firefox
    document.documentElement.scrollTop = 0
    // // 兼容safari

    // try {
    //     window.pageYOffset = 0
    // }catch(exception){
    //     console.log(exception.message);
    // }

    next()
})

export default router
